@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;600;700;800;900&family=Roboto:wght@100;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

* {
  background: black;
}

.navbar {
  font-family: 'Montserrat', sans-serif;
}

.logo {
  font-family: 'Pacifico', cursive;
  font-size: 1.5rem;
}